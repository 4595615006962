import classNames from "classnames";

import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Link from "./link";

import menu from "../styles/menu.module.css";
import components from "../styles/components.module.css";
import { menuLinksType } from "../types/sitemeta.types";

const triggerStyles = classNames(
  menu.trigger,
  components.lg_hidden,
  components.primary,
  components.strong
);

const menuLinkStyles = classNames(
  menu.navLink,
  components.primary,
  components.strong,
  components.block
);

export default function Menu(props) {
  const { menuLinks } = props;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 767.98px)");

  const closeMenu = () => {
    setDropdownOpen(false);
  };

  const handleDropdownClick = (e) => {
    setDropdownOpen(!dropdownOpen);
    e.preventDefault();
  };

  return (
    <nav className={menu.nav}>
      <a href="#Menu" onClick={handleDropdownClick} className={triggerStyles}>
        Menu
      </a>

      <div
        className={classNames(
          isMobile ? menu.dropdown : menu.flat,
          !dropdownOpen && components.lg_visible
        )}
      >
        <ol className={menu.navList}>
          {menuLinks.map(({ name, href, external }) => (
            <li className={menu.navListItem} key={name}>
              <Link
                {...(external ? { href } : { to: href })}
                external={external}
                onClick={closeMenu}
                className={menuLinkStyles}
              >
                {name}
              </Link>
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
}

Menu.propTypes = {
  menuLinks: menuLinksType.isRequired
};
