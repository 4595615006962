import React from "react";
import { Link } from "gatsby";

import Logo from "./logo";
import Menu from "./menu";
import Section from "./section";
import components from "../styles/components.module.css";
import header from "../styles/header.module.css";
import { siteTitleType, menuLinksType } from "../types/sitemeta.types";

export default function Header(props) {
  const { siteTitle, menuLinks } = props;

  return (
    <Section>
      <header className={header.container}>
        <h1 className={header.title}>
          <Link to="/" className={components.block}>
            <Logo title={siteTitle} />
          </Link>
        </h1>

        <Menu menuLinks={menuLinks} />
      </header>
    </Section>
  );
}

Header.propTypes = {
  siteTitle: siteTitleType.isRequired,
  menuLinks: menuLinksType.isRequired
};
