import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

// Building a custom theme
export default createMuiTheme({
  palette: {
    primary: {
      main: "#7054A1",
      light: "#a081d3",
      dark: "#422a72",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#fdd835",
      light: "#b19725",
      dark: "#fddf5d",
      contrastText: "#000"
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});
