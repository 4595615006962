import React from "react";

import logo from "../assets/images/cac_logo_landscape_purple_on_transparent.png";
import components from "../styles/components.module.css";
import { siteTitleType } from "../types/sitemeta.types";

export default function Logo(props) {
  const { title } = props;

  return (
    <img src={logo} alt={title} width="158" className={components.block} />
  );
}

Logo.propTypes = {
  title: siteTitleType.isRequired
};
