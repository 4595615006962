import React from "react";

import Logo from "./logo";
import Link from "./link";
import Section from "./section";

import components from "../styles/components.module.css";
import footer from "../styles/footer.module.css";

export default function Footer(props) {
  const { footerLinks } = props;

  return (
    <Section className={footer.container}>
      <footer className={footer.inner}>
        <Link to="/">
          <Logo title="Home Page" />
        </Link>

        <nav className={footer.nav}>
          <ol className={footer.list}>
            {footerLinks.map((item) => (
              <li className={footer.listItem} key={item.name}>
                <Link href={item.href} external className={components.primary}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ol>
        </nav>
      </footer>
    </Section>
  );
}
