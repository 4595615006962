import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import components from "../styles/components.module.css";

export default function Section(props) {
  return (
    <div
      className={classNames(components.section, props.className)}
      id={props.id}
    >
      <div className={components.section_inner}>{props.children}</div>
    </div>
  );
}

Section.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string
};
