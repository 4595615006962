import PropTypes from "prop-types";

export const siteTitleType = PropTypes.string;

export const menuLinkType = PropTypes.shape({
  name: PropTypes.string,
  href: PropTypes.string,
  external: PropTypes.boolean
});

export const menuLinksType = PropTypes.arrayOf(menuLinkType);
