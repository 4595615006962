import React from "react";
import { Link as InternalLink } from "gatsby";

export default function Link(props) {
  const { external, ...componentProps } = props;
  const LinkComponent = external
    ? ({ children, ...linkProps }) => (
        <a {...linkProps} target="_blank" rel="noopener">
          {children}
        </a>
      )
    : InternalLink;
  return <LinkComponent {...componentProps} />;
}
