import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

import Footer from "./footer";
import Header from "./header";
import useSiteMetadata from "../hooks/useSiteMetadata";
import theme from "../styles/theme";
import { trackEvent } from "../utils/tracking";

import "../../node_modules/normalize.css/normalize.css";
import "../styles/layout.css";

const trackingId = "UA-161202078-2";

const Layout = ({ children, hero }) => {
  const { title, menuLinks, footerLinks } = useSiteMetadata();

  React.useEffect(() => {
    if (!window.ga) {
      ReactGA.initialize(trackingId);
    }

    trackEvent({
      category: "Test",
      action: "Viewed"
    });
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap"
        />
      </Helmet>

      <main>
        <div className="hero">
          <Header siteTitle={title} menuLinks={menuLinks} />
          {hero || null}
        </div>

        <article>{children}</article>

        <Footer footerLinks={footerLinks}></Footer>
      </main>
    </MuiThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.node
};

export default Layout;
